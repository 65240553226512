<header>
  <div class="container my-3 my-md-5">
    <div class="row d-flex align-items-center">
      <div class="col-auto">
        <img class="logo" src="assets/landingFiles/images/logo.svg" alt="Осаго Альфабанк онлайн" loading="lazy">
      </div>
      <div class="col-auto">
        <img class="logo" src="assets/landingFiles/images/insapp-logo.svg" alt="Insapp" loading="lazy">
      </div>
     <div class="col alfaId text-end">
<!--       <app-alfa-id [isLoadApp]="isLoading"></app-alfa-id>-->
     </div>
    </div>
  </div>
</header>

@if (isMainPage) {
  <section class="container">
    <div class="title">
      <h2>Рассчитайте ОСАГО по номеру авто и получите кэшбэк 10% от стоимости полиса</h2>
    </div>
  </section>
}

<section class="osago">
  <div class="container osago-container">
<!--    <button class="btn btn-outline-primary btn-sm" (click)="getCacheExample()">cacheExample</button>-->
    @if (isLoading) {
      <div class="m-5 d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    } @else {
      <router-outlet></router-outlet>
    }
<!--    <pre>{{formService.form.value|json}}</pre>-->
  </div>
</section>

@if (isMainPage) {
  <section class="company-swiper my-3 my-md-5">
    <app-company-swiper-ab></app-company-swiper-ab>
  </section>

  <section class="advantages">
    <div class="container">
      <app-advantages></app-advantages>
    </div>
  </section>

  <section class="faq mt-5">
    <div class="container">
      <app-faq></app-faq>
    </div>
  </section>

  <section class="footer my-5">
    <app-footer></app-footer>
  </section>
}
