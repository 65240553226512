<div class="text-center d-flex justify-content-center align-items-center px-3">
  <div class="footer-text text-center">
    <a href="assets/landingFiles/promo.pdf" target="_blank">Правила акции</a><br>
    Услуга оказывается сервисом
    <a href="https://insapp.ru/" target="_blank">Insapp</a>.<br class="d-block d-sm-none"/>
    Поддержка пользователей
    <a href="mailto:support@insapp.ru">support&#64;insapp.ru</a>. <br />Ожидая
    ответ, проверяйте папку «Спам».
  </div>
</div>
