
<section class="container">
  <div class="title mb-3">
    <h2>Рассчитайте ОСАГО по номеру авто</h2>
  </div>

  <app-alfa-id [isLoadApp]="isLoading" [forProlongation]="true"></app-alfa-id>
</section>


@if (isLoading) {
  <div class="m-5 d-flex justify-content-center">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
}

