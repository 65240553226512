import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {concatMap, pipe, Subscription} from "rxjs";
import {AlfaIdService} from "../alfa-id.service";
import {CacheService} from "src/app/shared/services/cache.service";
import {AppService} from "src/app/shared/services/app.service";
import {FormService} from "src/app/shared/services/form.service";
import {NavigationService} from "src/app/shared/services/navigation.service";
import {ROUTES} from "src/app/shared/enums/routes.enum";
import {AlfaIdComponent} from "../alfa-id.component";

@Component({
  selector: 'app-prolongation-alfaid',
  standalone: true,
  imports: [
    AlfaIdComponent
  ],
  templateUrl: './prolongation-alfaid.component.html',
  styleUrl: './prolongation-alfaid.component.scss'
})
export class ProlongationAlfaidComponent implements OnInit, OnDestroy {

  public alfaIdService = inject(AlfaIdService);
  private cacheService = inject(CacheService);
  private appService = inject(AppService);
  private readonly formService = inject(FormService);
  private readonly navigationService = inject(NavigationService);

  // Подписка
  private subscription = new Subscription();

  // Индикатор загрузки
  public isLoading!: boolean;

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    this.getAlfaIdInfoFromCode();
  }

  // Уничтожение
  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // --------------------------------------------------------------------------

  // Получаем данные из Alfa ID по параметру code
  public getAlfaIdInfoFromCode(): void {

    // Создаем ссылку
    const url: URL = new URL(window.location.href);
    // Получаем параметры
    const params: URLSearchParams = url.searchParams;

    // Если есть code в параметрах url, то используем его
    const code: string | null = params.get('code');
    const applicationId: string | null = params.get('applicationId');

    if (code && applicationId) {
      this.isLoading = true;
      this.subscription.add(
        this.alfaIdService.getAlfaUseFromCode(true)
          .subscribe((res: any) => {
            if (res.result) {
              const {lastName, firstName, middleName} = res.value;
              this.alfaIdService.nameFromAlfaId = `${lastName} ${firstName?.charAt(0)}.${middleName?.charAt(0)}.`;

              // Если есть applicationId, то берем даныне из кэша и отпвляет клиента на стрницу оферов
              if (res.value?.prolongationApplicationId) {
                this.getOsagoByApplicationId(res.value?.prolongationApplicationId);
              } else if (res.value?.error || !res.value?.prolongationApplicationId) {
                this.navigationService.navigate(ROUTES.MainPage);
              }
            } else if (res.value?.error) {
              this.navigationService.navigate(ROUTES.MainPage);
            }
          })
      );
    }


  }

  // Получаем данные из кэша по applicationId
  public getOsagoByApplicationId(applicationId: string): void {
    this.isLoading = true;
    const urlParams = this.appService.getUrlParams();
    this.subscription.add(
      this.cacheService.getOsagoCacheByApplicationId(urlParams, applicationId)
        .pipe(
          concatMap(() => this.appService.getCarModels()),
        )
        .subscribe((res) => {
          setTimeout(() => {
            this.isLoading = false;
            if (this.formService.form.valid) {
              this.navigationService.navigate(ROUTES.Offers);
            } else {
              this.navigationService.navigate(ROUTES.MainPage);
            }
          }, 1500);
        })
    );
  }

}
