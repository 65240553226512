import { NgIf, NgOptimizedImage, JsonPipe } from '@angular/common';
import {AfterViewInit, ChangeDetectorRef, Component, inject, OnDestroy, OnInit} from '@angular/core';
import {FormControl, ReactiveFormsModule, Validators} from '@angular/forms';
import { AutocompleteComponent } from 'src/app/shared/components/autocomplete/autocomplete.component';
import { FormDateFieldComponent } from 'src/app/shared/components/form-date-field/form-date-field.component';
import { FormTextFieldComponent } from 'src/app/shared/components/form-text-field/form-text-field.component';
import { EditInfoComponent } from 'src/app/shared/components/policy-data/edit-info/edit-info.component';
import { AppService } from 'src/app/shared/services/app.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { ProlongationService } from 'src/app/shared/services/prolongation.service';
import { ProlongationFormService } from 'src/app/shared/services/prolongationForm.service';
import { FormPolicyAlertComponent } from '../../offers/form-policy-date/form-policy-alert/form-policy-alert.component';
import {concatMap, delay, EMPTY, filter, mergeMap, of, tap} from "rxjs";
import {CacheService} from "../../../shared/services/cache.service";
import {FormService} from "../../../shared/services/form.service";
import {OsagoService} from "../../../shared/services/osago.service";
import {APP_STATUS} from "../../../shared/enums/app-status.enum";
import moment from "moment-mini";
import {ValidationService} from "../../../shared/services/validation.service";
import {FormLicenseFieldComponent} from "../../../shared/components/form-license-field/form-license-field.component";
import {DaData} from "../../../shared/enums/da-data.enum";
import {ErrorMessageComponent} from "../../../shared/components/error-message/error-message.component";
import {BsModalService, ModalOptions} from "ngx-bootstrap/modal";
import {ModalService} from "../../../shared/services/modal.service";

@Component({
  selector: 'app-gos-number-form',
  standalone: true,
  imports: [
    EditInfoComponent,
    NgIf,
    NgOptimizedImage,
    FormDateFieldComponent,
    FormPolicyAlertComponent,
    ReactiveFormsModule,
    AutocompleteComponent,
    FormTextFieldComponent,
    JsonPipe,
    FormLicenseFieldComponent
  ],
  providers: [ModalService, BsModalService],
  templateUrl: './gos-number-form.component.html',
  styleUrl: './gos-number-form.component.scss'
})
export class GosNumberFormComponent implements OnInit, AfterViewInit, OnDestroy {
  public prolongationFormService = inject(ProlongationFormService);
  public appService = inject(AppService);
  private prolongationService = inject(ProlongationService);
  private navigationService = inject(NavigationService);
  private cacheService = inject(CacheService);
  private formService = inject(FormService);
  private osagoService = inject(OsagoService);
  private cd = inject(ChangeDetectorRef);
  private validationService = inject(ValidationService);
  public modalService = inject(BsModalService);

  // Форма "По номеру полиса"
  public formGosNumber = this.prolongationFormService.form.get('gosNumber') as any;

  // Флаг, идет загрузка списка
  public isLoading = false;
  // Флаг, показывать кнопку покупки нового полиса
  public isShowBtnNewPolicy = false;
  // Флаг, показывать кнопку покупки нового полиса
  public isInvalidForm = false;

  // ********************************************************************************
  // Инициализация
  ngOnInit(): void {

  }
  ngAfterViewInit(): void {
    this.formGosNumber.get('licensePlate')?.setValidators(
      [Validators.required, Validators.pattern('[А-Яа-яЁё]{1}[0-9]{3}[А-Яа-яЁё]{2}[0-9]{2,3}')]
    );
    this.formGosNumber.get('licensePlate')?.updateValueAndValidity();
    // Запуск нового цикла обнаружения изменений
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.formGosNumber.get('licensePlate')?.setValidators(null);
    this.formService.drivers.controls.forEach((control) => {
      const driverPassportDatePropertyControl = this.formService.propertyControls['driverPassportDate'];
      this.validationService.setControlValidation(driverPassportDatePropertyControl, control.get('driverPassportDate') as FormControl);
      control.get('driverPassportDate')?.updateValueAndValidity()
    });
    this.validationService.setControlValidation(this.formService.propertyControls['ownerPassportDate'], this.formService.owner.get('ownerPassportDate') as FormControl);
    this.validationService.setControlValidation(this.formService.propertyControls['insurerPassportDate'], this.formService.insurer.get('insurerPassportDate') as FormControl);
  }
  // ********************************************************************************

  public onSubmit(): void {
    console.log(this.formGosNumber);
    if (this.formGosNumber.invalid) {
      this.formGosNumber.markAllAsTouched();
      return;
    }

    this.isLoading = true;
    this.isInvalidForm = false;
    this.isShowBtnNewPolicy = false;

    this.osagoService.getAppInfo()
      .pipe(
        concatMap((res: any) => {
          if (res.result === true) {
            if (res.value.applicationData.applicationStatus === APP_STATUS.WidgetDisplayed) {
              return this.prolongationService.createOsagoReport();
            } else {
              this.osagoService.offers = [];
              return this.appService.createApplication()
                .pipe(
                  concatMap(() => this.prolongationService.createOsagoReport())
                );
            }
          } else {
            this.isLoading = false;
            this.modalService.show(ErrorMessageComponent, {
              initialState: {
                title: res.error.type,
                message: res.error.message,
              }
            } as ModalOptions);
            return of(null); // Возвращаем null для пропуска следующего блока
          }
        }),
        filter((res: any) => res !== null), // Пропускаем если res === null
        concatMap((res) => {
          const osagoData = res?.value?.osagoData;
          const hasRequiredData = osagoData && osagoData.series && osagoData.number;
          const isInsrerTypeAlfa = osagoData?.insurerType === 'Alfa';

          if (hasRequiredData && isInsrerTypeAlfa) {
            return this.prolongationService.getProlongationPolicyFromOsagoReport(res);
          } else {
            this.isLoading = false;
            this.modalService.show(ErrorMessageComponent, {
              initialState: {
                title: 'Полис не найден',
                message: `По данному госномеру полис в АльфаСтрахование не найден. <strong>Проверьте написание госномера.</strong>`,
                isShowBtnNewPolicy: true,
                isShowBtnGosNumber: true,
                isShowBtnPolicyNumber: true
              }
            } as ModalOptions);
            return of(null);
          }
        }),
        filter((res: any) => res !== null), // Пропускаем если res === null
        concatMap((response: any) => {
          if (response.result === true) {
            const urlParams = this.appService.getUrlParams();
            return this.cacheService.getOsagoCacheByApplicationId(urlParams)
              .pipe(
                delay(2000),
                concatMap(() => this.appService.getCarModels()),
                tap(() => {
                  this.formService.drivers.controls.forEach((control) => {
                    control.get('driverPassportDate')?.setValidators(null);
                    control.get('driverPassportDate')?.updateValueAndValidity();
                  });
                  this.formService.owner.get('ownerPassportDate')?.setValidators(null);
                  this.formService.owner.get('ownerPassportDate')?.updateValueAndValidity();
                  this.formService.insurer.get('insurerPassportDate')?.setValidators(null);
                  this.formService.insurer.get('insurerPassportDate')?.updateValueAndValidity();
                  this.formService.form.updateValueAndValidity();
                }),
                delay(1000),
                // Дожидаемся завершения всех операций внутри getOsagoCacheByApplicationId
                mergeMap((cacheResponse: any) => {
                  this.formService.drivers.controls.forEach((control) => control.get('driverPassportDate')?.clearValidators());
                  this.formService.owner.get('ownerPassportDate')?.clearValidators();
                  this.formService.insurer.get('insurerPassportDate')?.clearValidators();
                  // Если форма недействительна, выводим ошибку
                  if (this.formService.form.invalid) {
                    this.isLoading = false;
                    this.modalService.show(ErrorMessageComponent, {
                      initialState: {
                        title: 'Обновите данные',
                        message: 'Нашли Ваш полис, но анкетные данные нужно обновить. Пожалуйста, нажмите на кнопку "Обновите данные".',
                        isInvalidForm: true
                      }
                    } as ModalOptions);
                    return of(null); // Возвращаем null для пропуска следующего блока
                  }
                  // В противном случае, продолжаем обработку
                  return of(cacheResponse);
                })
              );
          } else {
            this.isLoading = false;
            this.modalService.show(ErrorMessageComponent, {
              initialState: {
                title: response.error.type,
                message: response.error.message,
                isShowBtnNewPolicy: false
              }
            } as ModalOptions);
            return of(null); // Возвращаем null для пропуска следующего блока
          }
        }),
        // Остальная часть кода (filter, concatMap, ...)
        filter((res: any) => res !== null), // Пропускаем если res === null
        concatMap(() => {

          const policyStartDate = this.formService.form.get('policyStartDate')?.value;
          const insurerType = this.formGosNumber.get('PreviousPolicyInsurerType')?.value;

          if (policyStartDate) {
            // Преобразуем дату из формата DD.MM.YYYY в объект moment
            const policyDate = moment(policyStartDate, 'DD.MM.YYYY');
            const currentDate = moment().startOf('day'); // Текущая дата без времени

            // Проверяем, если policyStartDate меньше или равен текущей дате
            if (policyDate.isSameOrBefore(currentDate)) {
              // Устанавливаем policyStartDate на следующий день
              const newPolicyStartDate = currentDate.add(1, 'day').format('DD.MM.YYYY');
              this.formService.form.get('policyStartDate')?.setValue(newPolicyStartDate);
              return this.appService.createApplication().pipe(
                concatMap(() => this.osagoService.sendOsagoApplication()),
                concatMap(() => this.osagoService.sendToInsurersGetOffers(insurerType))
              );
            } else {
              return this.osagoService.sendToInsurersGetOffers(insurerType);
            }
          } else {
            return this.osagoService.sendToInsurersGetOffers(insurerType);
          }
        }),
        filter(() => {
          const offers = this.osagoService.offers;
          if (offers.length === 0) {
            this.isLoading = false;
            this.modalService.show(ErrorMessageComponent, {
              initialState: {
                title: 'К сожалению, предложений нет',
                message: 'К сожалению, мы столкнулись со сложностью при продлении данного полиса онлайн. Вы можете оформить новый полис или обратиться за помощью в чат поддержки.',
                isShowBtnNewPolicy: true,
                isShowBtnNewChatMessage: true,
                isShowContinueButton: false
              }
            } as ModalOptions);
            this.isLoading = false;
            return false;
          } else if (offers.length > 0) {
            const selectedOffer = offers.find(offer => offer.insurerType === 'Alfa');
            if (selectedOffer) {
              this.osagoService.selectedOffer = selectedOffer;
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }

        }),
        concatMap(() => this.osagoService.selectOffer()),
      )
      .subscribe({
        next: () => {
          setTimeout(() => {
            this.navigationService.navigate('create-policy', { fromFastProlongation: true });
            this.isLoading = false;
          }, 1000);
        },
        error: (err) => {
          this.isLoading = false;
          this.modalService.show(ErrorMessageComponent, {
            initialState: {
              title: 'Ошибка!',
              message: err.message,
            }
          } as ModalOptions);
        }
      });
  }

  protected readonly daDataSystemName = DaData;
}
