import {Injectable} from '@angular/core';
import {
  AbstractControl, FormGroup,
  UntypedFormControl,
  UntypedFormGroup, ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {TextMaskHelper} from '../helpers/textmaskHelper';
import moment from 'moment-mini';
import {IProperty} from '../interface/property-controls.interface';
import {FormService} from "./form.service";

// Сервис валидации формы
@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor(private readonly formService: FormService) {
  }

  // Валидация номера авто и региона на главной странице
  public validationLicensePage(): boolean {
    const isProcessWithoutLicenseNumber = this.formService.form.get('carData')?.get('hasNoLicensePlate')?.value;
    return isProcessWithoutLicenseNumber ? isProcessWithoutLicenseNumber : this.formService.form.get('carData')?.get('licensePlate')?.valid as boolean;
  }

  // Применяем валидацию для контролла
  public setControlValidation(propertyControl: IProperty, control: UntypedFormControl): void {
    const validators: ValidatorFn | ValidatorFn[] | null = [];
    if (control && propertyControl && propertyControl?.validation?.values) {
      propertyControl?.validation?.values?.forEach((item) => {
        switch (item.validName) {
          case 'required':
            validators.push(Validators.required);
            break;
          case 'email':
            validators.push(Validators.email);
            validators.push(this.emailHostValidator());
            break;
          case 'min':
            validators.push(Validators.min(item.validValue || 0));
            break;
          case 'max':
            validators.push(Validators.max(item.validValue || 999));
            break;
          case 'minLength':
            validators.push(Validators.minLength(item.validValue || 0));
            break;
          case 'maxLength':
            validators.push(Validators.maxLength(item.validValue || 999));
            break;
          case 'pattern':
            validators.push(Validators.pattern(item.validValue));
            break;
          case 'birthDate':
            validators.push(this.birthDate('experienceDate'));
            break;
          case 'policyStartDate':
            validators.push(this.policyStartDate());
            break;
          case 'ownerBirthDate':
            validators.push(this.birthDate('ownerPassportDate'));
            break;
          case 'insurerBirthDate':
            validators.push(this.birthDate('insurerPassportDate'));
            break;
          case 'experienceStartDate':
            validators.push(this.experienceStartDate());
            break;
          case 'experienceYear':
            validators.push(this.experienceStartYear());
            break;
          case 'driverPassportDate':
            validators.push(this.passportDate('driverBirthDate'));
            break;
          case 'ownerPassportDate':
            validators.push(this.passportDate('ownerBirthDate'));
            break;
          case 'insurerPassportDate':
            validators.push(this.passportDate('insurerBirthDate'));
            break;
          case 'numberValidator':
            validators.push(this.numberValidator('creditRate'));
            break;
          case 'insuranceObjectBuiltYear':
            validators.push(this.insuranceObjectBuiltYearValidator());
            break;
          case 'maxDecimal':
            validators.push(this.maxDecimal());
            break;
          case 'stsDate':
            validators.push(this.stsDate('productionYear'));
            break;
          case 'ptsDate':
            validators.push(this.ptsDate('productionYear'));
            break;
          case 'ePtsDate':
            validators.push(this.ePtsDate('productionYear'));
            break;
          case 'documentDate':
            validators.push(this.documentDate());
            break;
          case 'driverDaDataRequired':
            validators.push(this.daDataRequired('driverAddressDaData'));
            break;
          case 'ownerDaDataRequired':
            validators.push(this.daDataRequired('ownerAddressDaData'));
            break;
          case 'insurerDaDataRequired':
            validators.push(this.daDataRequired('insurerAddressDaData'));
            break;
          case 'legalOwnerDaDataRequired':
            validators.push(this.daDataRequired('legalOwnerLegalAddressDaData'));
            break;
          case 'legalOwnerAddressDaDataRequired':
            validators.push(this.daDataRequired('legalOwnerAddressDaData'));
            break;
          case 'legalInsurerAddressDaDataRequired':
            validators.push(this.daDataRequired('legalInsurerAddressDaData'));
            break;
          case 'regCertificateDateIssue':
            validators.push(this.documentDate());
            break;
        }
      });
      control.setValidators(validators);
    }

    return;
  }

  // Валидация формы данных авто
  get validationCarDataForm(): boolean {
    return this.formService.form.get('carData')?.valid!;
  }

  // Валидация формы данных водителей
  get validationDriversDataForm(): boolean {
    const driversWithoutRestriction = this.formService.form.get('driversWithoutRestriction');
    return this.formService.form.get('drivers')?.valid! || driversWithoutRestriction?.value;
  }

  // Валидация формы собственника
  get validationOwnerDataForm(): boolean {
    return this.formService.form.get('owner')?.valid!;
  }

  // Валидация формы страхователь
  get validationInsurerDataForm(): boolean {
    return this.formService.form.get('insurer')?.valid!;
  }

  // Валидация даты рождения
  public birthDate(dependedControlName?: string): ValidatorFn {
    return (fc: AbstractControl): ValidationErrors | null => {
      const value = TextMaskHelper.getDate(fc.value);
      const dateFormat = 'DD.MM.YYYY';
      const birthDate = moment(value!, dateFormat, true);

      // Проверка корректности даты рождения и не превышает ли она текущую дату
      if (!birthDate.isValid() || birthDate.isAfter(moment())) {
        return {
          customValidation: true,
          message: 'Укажите корректную дату рождения'
        };
      }

      // Проверка, что возраст пользователя не меньше 16 лет
      const age16 = birthDate.clone().add(16, 'years');
      if (age16.isAfter(moment())) {
        return {
          customValidation: true,
          message: 'Должно быть больше 16 лет'
        };
      }

      // Получение контролов даты начала стажа и года начала стажа
      const parentGroup = fc.parent as UntypedFormGroup;
      if (!parentGroup) {
        return null; // Если родительская группа отсутствует, завершить валидацию
      }

      const experienceDateControl = parentGroup.get('experienceDate');
      const experienceYearControl = parentGroup.get('experienceYear');
      const driverPassportDateControl = parentGroup.get('driverPassportDate');

      // Валидация даты начала стажа
      if (experienceDateControl && experienceDateControl.enabled) {
        const experienceDateValue = TextMaskHelper.getDate(experienceDateControl.value);
        const experienceDate = moment(experienceDateValue!, dateFormat, true);

        if (!experienceDate.isValid()) {
          // Если дата начала стажа некорректна, возможно, другую валидацию обработает другой валидатор
          return null;
        }

        // Проверка, что дата начала стажа не раньше, чем дата рождения + 16 лет
        if (experienceDate.isBefore(age16, 'day')) {
          return {
            customValidation: true,
            message: 'Стаж начался в возрасте до 16 лет'
          };
        }
      }

      // Валидация года начала стажа
      if (experienceYearControl && experienceYearControl.enabled) {
        const experienceYearValue = experienceYearControl.value;

        if (experienceYearValue) {
          // Предполагается, что experienceYear содержит только год, создаём дату 31.12.<год>
          const experienceYearDateStr = `31.12.${experienceYearValue}`;
          const experienceYearDate = moment(experienceYearDateStr, dateFormat, true);

          if (!experienceYearDate.isValid()) {
            return {
              customValidation: true,
              message: 'Укажите корректный год начала стажа'
            };
          }

          // Проверка, что год начала стажа не раньше, чем дата рождения + 16 лет
          if (experienceYearDate.isBefore(age16, 'day')) {
            return {
              customValidation: true,
              message: 'Стаж начался в возрасте до 16 лет'
            };
          }
        }
      }

      // Валидация даты выдачи паспорта
      if (driverPassportDateControl && driverPassportDateControl.enabled) {
        const driverPassportDateValue = TextMaskHelper.getDate(driverPassportDateControl.value);
        const driverPassportDate = moment(driverPassportDateValue!, dateFormat, true);

        if (!driverPassportDate.isValid()) {
          // Если дата начала стажа некорректна, возможно, другую валидацию обработает другой валидатор
          return null;
        }

        // Проверка, что дата начала стажа не раньше, чем дата рождения + 16 лет
        if (driverPassportDate.isBefore(age16, 'day')) {
          return {
            customValidation: true,
            message: 'Дата выдачи паспорта не ранее чем 16 лет после даты рождения'
          };
        }
      }

      // Дополнительная валидация зависимых контролов, если указано
      if (dependedControlName && ['ownerPassportDate', 'insurerPassportDate'].includes(dependedControlName)) {
        const dependedControl = parentGroup.get(dependedControlName);
        if (dependedControl && dependedControl.enabled && dependedControl.value) {
          const dependedDateValue = TextMaskHelper.getDate(dependedControl.value);
          const dependedDate = moment(dependedDateValue!, dateFormat, true);

          if (!dependedDate.isValid()) {
            return {
              customValidation: true,
              message: `Укажите корректную дату для ${dependedControlName === 'ownerPassportDate' ? 'собстенника' : 'страхователя'}`
            };
          }

          // Проверка, что зависимая дата не раньше, чем дата рождения + 16 лет
          if (dependedDate.isBefore(age16, 'day')) {
            return {
              customValidation: true,
              message: `${dependedControlName === 'ownerPassportDate' ? 'Паспорт собственника' : 'Паспорт страхователя'} оформлен в возрасте до 16 лет`
            };
          }

          if (dependedControl && !dependedControl.valid) {
            dependedControl.updateValueAndValidity();
            return null;
          }
        }
      }

      // Если есть связанные контролы с ошибками, обновить их валидацию
      if (experienceDateControl && !experienceDateControl.valid) {
        experienceDateControl.updateValueAndValidity();
        return null;
      }

      if (experienceYearControl && !experienceYearControl.valid) {
        experienceYearControl.updateValueAndValidity();
        return null;
      }

      // Если все проверки пройдены, вернуть null (ошибок нет)
      return null;
    };
  }


// Валидация даты начала стажа
  public experienceStartDate(
    ignore: () => boolean = () => false,
    birthDateFieldName: string = 'driverBirthDate'
  ): ValidatorFn {
    return (fc: AbstractControl): ValidationErrors | null => {
      // Если функция ignore возвращает true, пропускаем валидацию
      if (ignore()) {
        return null;
      }

      const value = TextMaskHelper.getDate(fc.value);
      const dateFormat = 'DD.MM.YYYY';
      const experienceDate = moment(value!, dateFormat, true);

      // Проверка, что дата указана и корректна
      if (!value || !experienceDate.isValid()) {
        return {
          customValidation: true,
          message: 'Укажите корректную дату начала стажа'
        };
      }

      // Проверка, что дата не превышает текущую дату
      if (experienceDate.isAfter(moment(), 'day')) {
        return {
          customValidation: true,
          message: 'Дата начала стажа не может быть в будущем'
        };
      }

      // Получение поля даты рождения из родительской формы
      const parentGroup = fc.parent as UntypedFormGroup;
      if (!parentGroup) {
        return null; // Если родительская группа отсутствует, завершаем валидацию
      }

      const birthDateControl = parentGroup.get(birthDateFieldName);
      if (birthDateControl) {
        // Если дата рождения некорректна, обновляем её валидность и пропускаем текущую валидацию
        if (!birthDateControl.valid) {
          birthDateControl.updateValueAndValidity();
          return null;
        }

        const birthDateValue = TextMaskHelper.getDate(birthDateControl.value);
        const birthDate = moment(birthDateValue!, dateFormat, true);

        // Проверка, что дата рождения корректна
        if (!birthDate.isValid()) {
          return {
            customValidation: true,
            message: 'Укажите корректную дату рождения'
          };
        }

        const minimumStartDate = birthDate.clone().add(16, 'years');

        // Проверка, что дата начала стажа не раньше даты рождения + 16 лет
        if (experienceDate.isBefore(minimumStartDate, 'day')) {
          return {
            customValidation: true,
            message: 'Стаж начался в возрасте до 16 лет'
          };
        }
      }

      return null; // Если все проверки пройдены, возвращаем null
    };
  }

// Валидация года начала стажа
  public experienceStartYear(
    ignore: () => boolean = () => false,
    birthDateFieldName: string = 'driverBirthDate'
  ): ValidatorFn {
    return (fc: AbstractControl): ValidationErrors | null => {
      // Если функция ignore возвращает true, пропускаем валидацию
      if (ignore()) {
        return null;
      }

      const yearValue = TextMaskHelper.getYear(fc.value);
      const currentYear = moment().year();

      // Проверка, что год указан и не превышает текущий
      if (!yearValue || isNaN(Number(yearValue)) || Number(yearValue) > currentYear) {
        return {
          customValidation: true,
          message: 'Укажите корректный год начала стажа'
        };
      }

      const dateFormat = 'DD.MM.YYYY';
      const experienceYearDateStr = `31.12.${yearValue}`;
      const experienceYearDate = moment(experienceYearDateStr, dateFormat, true);

      // Проверка, что сформированная дата корректна
      if (!experienceYearDate.isValid()) {
        return {
          customValidation: true,
          message: 'Укажите корректный год начала стажа'
        };
      }

      // Получение поля даты рождения из родительской формы
      const parentGroup = fc.parent as UntypedFormGroup;
      if (!parentGroup) {
        return null; // Если родительская группа отсутствует, завершаем валидацию
      }

      const birthDateControl = parentGroup.get(birthDateFieldName);
      if (birthDateControl) {
        // Если дата рождения некорректна, обновляем её валидность и пропускаем текущую валидацию
        if (!birthDateControl.valid) {
          birthDateControl.updateValueAndValidity();
          return null;
        }

        const birthDateValue = TextMaskHelper.getDate(birthDateControl.value);
        const birthDate = moment(birthDateValue!, dateFormat, true);

        // Проверка, что дата рождения корректна
        if (!birthDate.isValid()) {
          return {
            customValidation: true,
            message: 'Укажите корректную дату рождения'
          };
        }

        const minimumStartDate = birthDate.clone().add(16, 'years');

        // Проверка, что год начала стажа не раньше даты рождения + 16 лет
        if (experienceYearDate.isBefore(minimumStartDate, 'day')) {
          return {
            customValidation: true,
            message: 'Стаж начался в возрасте до 16 лет'
          };
        }
      }

      return null; // Если все проверки пройдены, возвращаем null
    };
  }

  // Валидация контролла год выпуска авто
  public productionYear(ignore = () => false): ValidatorFn {
    return (fc: AbstractControl) => {
      const stsDate = (fc.parent as UntypedFormGroup)?.get('stsDate');
      stsDate?.updateValueAndValidity();
      const ptsDate = (fc.parent as UntypedFormGroup)?.get('ptsDate');
      ptsDate?.updateValueAndValidity();
      const ePtsDate = (fc.parent as UntypedFormGroup)?.get('ePtsDate');
      ePtsDate?.updateValueAndValidity();
      return null;
    };
  }

// Валидация даты выдачи паспорта
  public passportDate(
    birthdateFieldName: string,
    ignore: () => boolean = () => false
  ): ValidatorFn {
    return (fc: AbstractControl): ValidationErrors | null => {
      // Пропуск валидации, если функция ignore возвращает true
      if (ignore()) {
        return null;
      }

      const dateFormat = 'DD.MM.YYYY';
      const value = TextMaskHelper.getDate(fc.value);

      // Проверка наличия значения
      if (!value) {
        return {
          customValidation: true,
          message: 'Укажите корректную дату выдачи паспорта'
        };
      }

      // Парсинг даты выдачи паспорта
      const passportDate = moment(value, dateFormat, true);

      // Проверка корректности даты и того, что она не в будущем
      if (!passportDate.isValid() || passportDate.isAfter(moment(), 'day')) {
        return {
          customValidation: true,
          message: 'Укажите корректную дату выдачи паспорта'
        };
      }

      // Проверка, что дата выдачи паспорта не старше 100 лет от текущей даты
      const maxIssueDate = moment().add(100, 'years');
      if (!maxIssueDate.isAfter(moment())) {
        return {
          customValidation: true,
          message: 'Укажите корректную дату выдачи паспорта'
        };
      }

      // Получение контрола даты рождения из родительской формы
      const parentGroup = fc.parent as UntypedFormGroup;
      if (!parentGroup) {
        // Если родительская группа отсутствует, завершить валидацию
        return null;
      }

      const birthDateControl = parentGroup.get(birthdateFieldName);
      if (!birthDateControl) {
        // Если контрол даты рождения отсутствует, завершить валидацию
        return null;
      }

      const birthDateValue = TextMaskHelper.getDate(birthDateControl.value);
      const birthDate = moment(birthDateValue!, dateFormat, true);

      // Проверка корректности даты рождения
      if (!birthDate.isValid()) {
        return {
          customValidation: true,
          message: 'Укажите корректную дату рождения'
        };
      }

      // Вычисление минимальной допустимой даты выдачи паспорта (дата рождения + 14 лет)
      const minimumPassportIssueDate = birthDate.clone().add(14, 'years');

      // Проверка, что дата выдачи паспорта не раньше минимальной допустимой даты
      if (passportDate.isBefore(minimumPassportIssueDate, 'day')) {
        return {
          customValidation: true,
          message: `Дата выдачи паспорта должна быть не раньше чем через 14 лет от даты рождения (${minimumPassportIssueDate.format('DD.MM.YYYY')})`
        };
      }

      // Если все проверки пройдены, вернуть null (ошибок нет)
      return null;
    };
  }

  // Валидация даты начала стажа
  public policyStartDate(ignore = () => false): ValidatorFn {
    return (fc: AbstractControl) => {
      const value = TextMaskHelper.getDate(fc.value);
      if (value == null) {
        return {
          customValidation: true,
          message: 'Укажите корректную дату'
        };
      }
      const minDate = moment();

      const date = moment(value, 'DD.MM.YYYY', true);
      if (!date.isValid() || date.isBefore(minDate)) {
        return {
          customValidation: true,
          message: 'Дата должна быть больше текущей'
        };
      }

      if (!moment(value, 'DD.MM.YYYY', true).isBefore(moment().add(69, 'days'))) {
        return {
          customValidation: true,
          message: 'Дата начала договора не может быть более чем через 69 дней'
        };
      }

      return null;
    };
  }

  // Обязательный выбор из списка, полученного с сервиса daData
  public daDataRequired(daDataFieldName: string): ValidatorFn {
    return (fc: AbstractControl) => {
      const cityDaData = (fc.parent as UntypedFormGroup)?.get(daDataFieldName)?.value;
      if (
        (cityDaData?.data && !(Number(cityDaData?.data?.fias_level) >= 8
            || (Number(cityDaData?.data?.fias_level) === 7 && cityDaData?.data?.house != null))
        )
        || (cityDaData && cityDaData?.fias_level && !(Number(cityDaData?.fias_level) >= 8
            || (Number(cityDaData?.fias_level) === 7 && cityDaData?.house != null))
        )
      ) {
        return {
          customValidation: true,
          message: 'Укажите адрес до дома'
        };
      }

      return null;
    };
  }

  // Валидация номера квартиры в адресе
  public daDataAddressHouseFlatNumber(daDataFieldName: string): ValidatorFn {
    return (fc: AbstractControl) => {
      const cityDaData = (fc.parent as UntypedFormGroup)?.get(daDataFieldName)?.value;

      // Если нету номера квартиры
      if (cityDaData && !cityDaData?.flat) {
        return {
          customValidation: true,
          message: 'Укажите полный адрес'
        };
      }

      return null;
    };
  }

  // Вадлидация смс кода
  public errorCode(isValidCode: boolean): ValidatorFn {
    return (fc: AbstractControl) => {

      if (!isValidCode) {
        return {
          customValidation: true,
          message: 'Вы ввели неверный смс код'
        };
      }

      return null;
    };
  }

  // Валидация целых и дробных чисел
  public numberValidator(fieldName: string, ignore = () => false): ValidatorFn {
    return (fc: AbstractControl) => {
      if (ignore()) {
        return null;
      }

      const control = (fc.parent as UntypedFormGroup)?.get(fieldName);

      const isNumber = /^\d+(\.\d{1,2})?$/.test(control?.value);
      return isNumber ? null : {invalidNumber: true};
    };
  }

  // Валидация год постройки дома
  public insuranceObjectBuiltYearValidator(ignore = () => false): ValidatorFn {
    return (fc: AbstractControl) => {
      if (ignore()) {
        return null;
      }

      const currentYear = new Date().getFullYear();


      if (Number(fc.value) > currentYear) {
        return {
          customValidation: true,
          message: 'Год постройки не может быть больше текущего'
        };
      }

      return null;
    };
  }

  // Валидация годовая ставка
  public maxDecimal(ignore = () => false): ValidatorFn {
    return (fc: AbstractControl) => {
      if (ignore()) {
        return null;
      }

      const stringNumber = fc?.value?.toString().replace(',', '.');

      if (stringNumber > 20) {
        return {
          customValidation: true,
          message: 'Максимальная ставка 20%'
        };
      }

      return null;
    };
  }

  // Валидация sts даты
  public stsDate(dependedControlName?: string): ValidatorFn {
    return (fc: AbstractControl) => {
      const value = TextMaskHelper.getDate(fc.value);

      const date = moment(value || '', 'DD.MM.YYYY', true);
      if (!date.isValid() || date.isAfter()) {
        return {
          customValidation: true,
          message: 'Укажите корректную дату выдачи СТС документа'
        };
      }

      if (dependedControlName === 'productionYear') {
        // Получаем зависимый контрол (год выпуска авто)
        const dependedControl = (fc.parent?.parent as UntypedFormGroup)?.get(dependedControlName!);
        if (dependedControl && dependedControl.value) {
          const yearOfManufacture = moment(dependedControl.value, 'YYYY', true);
          // Дата выдачи СТС не должна быть старше года выпуска авто
          if (date.isBefore(yearOfManufacture.startOf('year'))) {
            return {
              customValidation: true,
              message: 'Свитедельство транспортного средства не может быть старше года выпуска авто'
            };
          }
        }
      }

      return null;
    };
  }

  // Валидация pts даты
  public ptsDate(dependedControlName?: string): ValidatorFn {
    return (fc: AbstractControl) => {
      const value = TextMaskHelper.getDate(fc.value);

      const date = moment(value || '', 'DD.MM.YYYY', true);
      if (!date.isValid() || date.isAfter()) {
        return {
          customValidation: true,
          message: 'Укажите корректную дату ПТС документа'
        };
      }

      if (dependedControlName === 'productionYear') {
        // Получаем зависимый контрол (год выпуска авто)
        const dependedControl = (fc.parent?.parent as UntypedFormGroup)?.get(dependedControlName!);
        if (dependedControl && dependedControl.value) {
          const yearOfManufacture = moment(dependedControl.value, 'YYYY', true);
          // Дата выдачи СТС не должна быть старше года выпуска авто
          if (date.isBefore(yearOfManufacture.startOf('year'))) {
            return {
              customValidation: true,
              message: 'ПТС не может быть старше года выпуска авто'
            };
          }
        }
      }

      return null;
    };
  }

  // Валидация ePts даты
  public ePtsDate(dependedControlName?: string): ValidatorFn {
    return (fc: AbstractControl) => {
      const value = TextMaskHelper.getDate(fc.value);

      const date = moment(value || '', 'DD.MM.YYYY', true);
      if (!date.isValid() || date.isAfter()) {
        return {
          customValidation: true,
          message: 'Укажите корректную дату еПТС документа'
        };
      }

      if (dependedControlName === 'productionYear') {
        // Получаем зависимый контрол (год выпуска авто)
        const dependedControl = (fc.parent?.parent as UntypedFormGroup)?.get(dependedControlName!);
        if (dependedControl && dependedControl.value) {
          const yearOfManufacture = moment(dependedControl.value, 'YYYY', true);
          // Дата выдачи СТС не должна быть старше года выпуска авто
          if (date.isBefore(yearOfManufacture.startOf('year'))) {
            return {
              customValidation: true,
              message: 'еПТС не может быть старше года выпуска авто'
            };
          }
        }
      }

      return null;
    };
  }

  // Валидация sts даты
  public documentDate(dependedControlName?: string): ValidatorFn {
    return (fc: AbstractControl) => {
      const value = TextMaskHelper.getDate(fc.value);

      const date = moment(value || '', 'DD.MM.YYYY', true);
      if (!date.isValid() || date.isAfter()) {
        return {
          customValidation: true,
          message: 'Укажите корректную дату'
        };
      }

      return null;
    };
  }

  // Валидация email
  emailHostValidator(): ValidatorFn {
    return (fc: AbstractControl) => {

      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const hasDotInHost = /@[^@]+\.[^@]+$/.test(fc.value);

      const patternInvalid = emailPattern.test(fc.value) && hasDotInHost ? null : {emailHost: true}

      if (patternInvalid) {
        return {
          customValidation: true,
          message: 'Укажите корректный email'
        };
      }

      return null;
    };
  }

}
