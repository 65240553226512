import { Component } from '@angular/core';

@Component({
  selector: 'app-advantages',
  standalone: true,
  templateUrl: './advantages.component.html',
  styleUrl: './advantages.component.scss'
})
export class AdvantagesComponent {

}
